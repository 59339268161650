
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Questions {
  padding-bottom: spacing(4);
 
}

.Question {
  border: none;
  padding-bottom: spacing(4);
  color: $color-primary-grey;
}

.Option {
  color: $color-primary-black;
   .designV2 & {
    color: $color-grey-medium;
    font-size: 18px;
  }
}

.Description {
  color: $color-primary-grey;
  padding-bottom: spacing(3);
}

.QuestionText {
  padding-bottom: spacing(2);
  .designV2 & {
    color: $color-grey-medium;
    font-size: 20px;
  }
}

.Button {
  padding: spacing(2) spacing(12);
}

.isDark {
  .Description,
  .Question,
  .Option,
  .QuestionText {
    color: $color-primary-white;
  }
}

.Title {
  color: $color-primary-teal;
}
.TitleV2 {
  color: $color-primary-teal;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.2;
  font-family: $font-heading;

  @include mq($breakpoint-desktop) {
    font-size: 32px;
  }
}