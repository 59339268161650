
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  &.dark {
    background-color: $color-primary-grey;
  }
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    padding: 24px 0;
    row-gap: 24px;
  }
}
.TopContent,
.MainContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.TopContent {
  h2 {
    color: $color-primary-teal !important;
  }
}
.CarouselContainer {
  width: 100%;
  height: 100%;
}
.CarouselContent {
  position: relative; //relative w-full overflow-hidden
  width: 100%;
  overflow: hidden;
}
.CarouselContentWrap {
  display: flex;
  height: auto;
  column-gap: 16px;
  @include mq($breakpoint-desktop) {
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.CarouselItemContainer {
  display: flex;
  min-width: 0;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  flex-basis: 80%;
  border-radius: 9px;
  flex-direction: column;
  position: relative;
  min-height: 255px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: brightness(110%);
  }
  @include mq($breakpoint-tablet) {
    min-height: 300px;
  }
  @include mq($breakpoint-desktop) {
    min-height: 350px;
  }
}
.CarouselItemLabel {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 24px;
  font-weight: 900;
  color: white;
  height: 22%;
  display: flex;
  align-items: center;
  font-family: $font-heading;
}
.CarouselDots {
  display: flex;
  justify-content: center;
  column-gap: 12px;
}
.CarouselDot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.16);
  @include mq($breakpoint-desktop) {
    display: none;
  }
  &.ActiveCarouselDot {
    background-color: black;
  }
}
:global(.groupedWidgetsLayout) {
  & .Container {
   box-shadow: none;
   padding: 0;
  }
}
