
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ItemWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

.ImageWrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 66.25%;
  border-radius: 9px;
  align-self: start;
  div,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

a.ImageWrap:hover {
  filter: brightness(120%);
}

.ItemContentWrap {
  @include mq($breakpoint-desktop) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.RatingIconWrap {
  width: 55px;
  flex-shrink: 0;
}

.RatingIcon {
  width: 100% !important;
  height: 100% !important;
}

.MediaTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.05;
  font-family: $font-heading;
  color: black;
  .dark & {
    color: $color-primary-teal;
  }
}

.TopContentWrap {
  display: block;
  &:hover h3 {
    text-decoration: underline;
  }
}

.TopContent {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.ShortFormInsight {
  padding-top: 5px;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 65px;
  @each $name, $value in $color-rating-list {
    &.Rating-#{$name} {
      color: $value;
    }
  }
  @include mq($breakpoint-desktop) {
    -webkit-line-clamp: none;
    line-clamp: none;
    max-height: auto;
  }
}

.SummaryWrap {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500px;
  line-height: 1.3;
  letter-spacing: -0.09px;
  overflow: auto;
  display: -webkit-box;
  max-height: 320px;
  -webkit-box-orient: vertical;
  font-family: $font-body;
  color: $color-grey-medium;
  .dark & {
    color: white;
  }
}

.ContentAdviceWrap {
  padding-top: 32px;
  padding-bottom: 32px;
}

.ContentAdvice {
  font-family: $font-body;
  font-weight: normal;
  overflow: hidden;
  min-height: 80px;
  font-size: 18px;
  color: $color-grey-medium;
}
