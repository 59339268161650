
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Mailchimp {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}
:global(.groupedWidgetsLayout) {
  & .Mailchimp {
    padding:0;
    h1 {
      font-size: 28px;
      font-family: $font-heading;
      font-weight: 800;
      @include mq($breakpoint-desktop) {
        font-size: 32px;
      }
    }
  }
 
}