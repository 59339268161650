
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.IconButton {
  vertical-align: middle;
  height: spacing(6);
  min-width: spacing(6);
  white-space: nowrap;
  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }
}

.IconButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  span {
    display: flex;
  }
  svg {
    vertical-align: top;
  }
}

.IconButtonText {
  margin-left: spacing(0.5);
}

.rounded {
  border-radius: 50%;
}

.solid {
  color: $color-primary-white;
  background-color: $color-primary-teal;

  &:focus,
  &:hover {
    color: $color-primary-white;
    background-color: $color-primary-black;
  }
  &:active {
    background-color: $color-primary-black;
  }
}

.small {
  height: spacing(4);
  min-width: spacing(4);
}

.increaseHitArea {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: spacing(-1);
    right: spacing(-1);
    bottom: spacing(-1);
    left: spacing(-1);
  }
}
