
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    padding: 44px;
    max-width: 1560px;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @include mq($breakpoint-desktop) {
    padding: 30px 0 0;
    row-gap: 30px;
  }
}
.TopContent {
  display: flex;
  row-gap: 24px;
  justify-content: space-between;
  align-items: center;
  a {
    color: $color-primary-grey;
    text-decoration: underline;
  }
  h2 {
    color: $color-primary-teal !important;
  }
}
.MainContent {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-family: $font-body;
  row-gap: 30px;
}

.FeaturedNewsWrap {
  padding: 0;

  @include mq($breakpoint-desktop) {
    background-color: $color-common-white;
    padding: 22px;
    border-radius: 9px;
    box-shadow: 0 0 58px 0 rgba(55, 74, 82, 0.3);
    min-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 44px 33px;
  }
  @include mq($breakpoint-desktopLarge) {
    padding: 44px;
  }
}
.FeaturedNews {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 30px;
  }
}

.FeaturedNewsTitle {
  color: $color-primary-teal;
  font-family: $font-heading;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.36;
  margin-top: 11px;
  margin-bottom: 16px;
  @include mq($breakpoint-desktop) {
    font-size: 30px;
  }
}

.FeaturedNewsCTALabel {
  color: $color-primary-grey;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  text-decoration: underline;
  font-family: $font-body;
}

.NewsDate {
  color: $color-grey-medium;
  font-weight: 500;
  font-family: $font-body;
  letter-spacing: -0.09px;
  font-size: 18px;
  &.FeaturedNewsDate {
    color: $color-primary-grey;
  }
}
.FeaturedNewsRightCol {
  @include mq($breakpoint-desktop) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.NewsDescription {
  color: $color-grey-medium;
  font-weight: 500;
  letter-spacing: -0.09px;
  font-size: 18px;
  line-height: 1.3;
  &.FeaturedNewsDescription {
    display: none;
    @include mq($breakpoint-desktop) {
      display: block;
      margin-top: 11px;
      margin-bottom: 16px;
    }
  }
}
.NewsItem {
  padding: 24px 0;
  border-top: 1px solid $color-grey-dark;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &:last-child {
    padding-bottom: 0;
  }
}
.NewsItemTitle {
  color: $color-common-black;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.15;
  font-family: $font-heading;
}

.NewsItem:hover,
.FeaturedNewsWrap:hover {
  h3 {
    text-decoration: underline;
  }
}
.Link {
  &:hover {
    color: $color-primary-teal;
  }
}
:global(.groupedWidgetsLayout) {
  .Container {
    box-shadow: none;
    padding:0;
  }
}