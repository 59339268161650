.BreadCrumbWrapper {
  padding: spacing(2) 0;

  @include mq($breakpoint-desktop) {
    padding: spacing(3) 0;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) 0;
  }
}

.BreadCrumb {
  line-height: 2;

  &:hover {
    color: $color-primary-teal;
    transition: $easing-standard $duration-short;
    &::after {
      color: $color-primary-white;
    }
  }

  &:not(:last-of-type)::after {
    content: ' / ';
  }
}
