
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MediaItem {
  display: flex;
  align-items: center;
  text-align: left;
  border-top: 1px solid $color-primary-grey-lighter;
  padding: spacing(2) 0;

  &:last-child {
    border-bottom: 1px solid $color-primary-grey-lighter;
    margin-bottom: spacing(2);
  }

  &:hover {
    .Title {
      color: $color-primary-teal;
      transition: 0.25s $easing-standard;
    }
  }
}

.ImageWrapper {
  margin-right: spacing(3);
  width: 100%;
  max-width: 90px;
  height: 90px;
  flex-shrink: 0;

  @include mq($breakpoint-desktop) {
    max-width: 125px;
    height: 125px;
  }

  @include mq($breakpoint-desktop) {
    max-width: 140px;
    height: 140px;
  }

  @include mq($breakpoint-desktopMedium) {
    max-width: 225px;
    height: 225px;
  }
}

.Image {
  border-radius: 50px;
}

.RatingWrapper {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-right: spacing(3);
  background-color: $color-primary-grey-lightest;
  border-radius: 50%;
  max-width: 90px;
  height: 90px;

  @include mq($breakpoint-desktop) {
    max-width: 125px;
    height: 125px;
  }

  @include mq($breakpoint-desktop) {
    max-width: 140px;
    height: 140px;
  }

  @include mq($breakpoint-desktopMedium) {
    max-width: 225px;
    height: 225px;
  }
}

.Rating {
  width: 55px !important;
  height: 55px !important;
  margin: auto;

  @include mq($breakpoint-tabletLandscape) {
    width: 63px !important;
    height: 63px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 71px !important;
    height: 71px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 131px !important;
    height: 131px !important;
  }
}

.Description {
  margin-right: spacing(2);
}

.PublishedDate {
  color: $color-primary-grey-light;
}

.isDark {
  .Description,
  .Title {
    color: $color-primary-white;
  }
  .PublishedDate {
    color: $color-primary-grey-lighter;
  }
}

.designV2 {
  .Title {
font-size: 28px;
   @include mq($breakpoint-desktop) {
    font-size: 32px;
  }
  }
  .Description {
 font-size: 18px;
  font-weight: 500;
  color: $color-grey-medium;
  }
}