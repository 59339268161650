
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MediaType {
  font-weight: 500;
  text-transform: uppercase;
  color: $color-primary-teal;
  display: none;

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2);
    display: block;
  }
}

.TitleWrapper {
  margin-bottom: spacing(1.5);
  display: table;

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: spacing(3);
  }

  .large & {
    margin-bottom: spacing(0.5);

    @include mq($breakpoint-desktopMedium) {
      margin-bottom: spacing(3);
    }
  }

  .huge & {
    margin-bottom: spacing(2);

    @include mq($breakpoint-desktop) {
      margin-bottom: spacing(3);
    }
  }
}

.Title {
  color: $color-primary-black;
  display: table-cell;
  vertical-align: middle;
}

.SubTitle {
  margin-bottom: spacing(3);
  &.fixed {
    height: 60px;

    @include mq($breakpoint-desktopMedium) {
      height: 100px;
    }

    @include mq($breakpoint-desktopLarge) {
      height: 120px;
    }
  }
}

.Rating {
  display: table-cell;
  padding-right: spacing(1.5);
  width: 1px;
  vertical-align: top;

  @include mq($breakpoint-desktopLarge) {
    padding-right: spacing(3);
  }
}

.Icon {
  width: 39px !important;
  height: 35px !important;

  @include mq($breakpoint-mobileLandscape) {
    width: 55px !important;
    height: 49px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 71px !important;
    height: 63px !important;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 90px !important;
    height: 80px !important;
  }

  .huge & {
    width: 67.5px !important;
    height: 60px !important;

    @include mq($breakpoint-desktop) {
      width: 96.5px !important;
      height: 85.8px !important;
    }

    @include mq($breakpoint-desktopLarge) {
      width: 117px !important;
      height: 117px !important;
    }
  }

  .large & {
    width: 44px !important;
    height: 44px !important;

    @include mq($breakpoint-tablet) {
      width: 55px !important;
      height: 55px !important;
    }

    @include mq($breakpoint-desktop) {
      width: 72px !important;
      height: 72px !important;
    }

    @include mq($breakpoint-desktopMedium) {
      width: 94px !important;
      height: 94px !important;
    }

    @include mq($breakpoint-desktopLarge) {
      width: 117px !important;
      height: 117px !important;
    }
  }
}

.Type {
  font-weight: 500;
  text-transform: uppercase;
}

.isDark {
  .Title {
    color: $color-primary-white;
  }
}
.designV2 {
  .Title {
    font-size: 28px;
    font-family: $font-heading;
    @include mq($breakpoint-desktop) {
      font-size: 32px;
    }
  }
}