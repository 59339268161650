
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContainerWrapper {
  position: relative;
  &.OverlapHero {
    margin-top: 0;
    @include mq($breakpoint-tablet) {
      margin-top: -60px;
    }
    @include mq($breakpoint-desktop) {
     margin-top: -125px;
    }
  }
}
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);

  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    padding: 44px;
    max-width: 1560px;
  }
  .dark & {
    background-color: $color-primary-grey;
  }
}
.Content {
  display: flex;
  flex-direction: column;
}
.TopContent {
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  @include mq($breakpoint-desktop) {
    padding-bottom: 32px;
  }
}
.ContentHeading {
  font-family: $font-heading;
  font-size: 28px;
  color: $color-primary-teal;
  font-weight: 900;
  line-height: 1.21;
  @include mq($breakpoint-desktop) {
    font-size: 34px;
  }
}
.CarouselContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.CarouselContent {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.CarouselContentWrap {
  display: flex;
  height: auto;
  column-gap: 16px;
}
.CarouselDots {
  display: flex;
  justify-content: center;
  column-gap: 12px;
  margin-top: 16px;
  @include mq($breakpoint-desktop) {
    margin-top: 32px;
  }
}
.CarouselDot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.16);
  transition: all ease-in-out 0.3s;

  &.ActiveCarouselDot {
    background-color: black;
  }

  &:hover {
    background-color: $color-primary-teal;
    opacity: 0.5;
  }
}
.CarouselButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: space-between;
  transform: translate(-50%, -50%);
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
    width: 100%;
  }
}
.CarouselButton {
  display: none;
  padding: 12px;
  background-color: white;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  position: relative;
  transition: all ease-in-out 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba($color-primary-black, 0.4);
  }
  @include mq($breakpoint-desktop) {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: translate(25px);
  }
  @include mq($breakpoint-desktopLarge) {
    width: 62px;
    height: 62px;
    border-radius: 9px;
    transform: translate(31px);
  }
  &.DisabledButton {
    opacity: 0;
  }
}
.PrevButton {
  transform: rotate(180deg) translate(25px);
  @include mq($breakpoint-desktopLarge) {
    transform: rotate(180deg) translate(31px);
  }
}

:global(.groupedWidgetsLayout) {
  .Container {
    box-shadow: none;
    padding: 0;
  }
  .CarouselButton {
    display: none;
  }
}