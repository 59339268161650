
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../../../Primitive/Type/Type.module.scss';

.Bio {
  margin: 0 0 spacing(2);

  @include mq($breakpoint-tablet) {
    margin-bottom: 0;
  }
}

.BioWrapper {
  padding: spacing(1) 0 spacing(2);

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(2) 0;
  }
}

.Expander {
  @extend .base-large;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  font-weight: bold;
  color: $color-primary-teal;
  margin-bottom: spacing(1);
}

.ExpanderCopy {
  font-weight: normal;
  color: initial;
  padding-bottom: spacing(2);
  @extend .base;

  .isDark & {
    color: $color-primary-white;
  }
}

.isDark {
  &.Bio {
    color: $color-primary-white;
  }

}

.Title {
  color: $color-primary-teal;
}
.IconV2 {
  svg {
    path {
      fill: $color-primary-grey;
    }
  }
  .isDark & {
    svg {
      path {
        fill: white;
      }
    }
  }
}
.NameV2 {
  color: $color-primary-grey;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  .isDark & {
    color: $color-primary-white;
  }
}
.TitleV2 {
  color: $color-primary-grey;
  font-size: 20px;
  line-height: 1.2;
  .isDark & {
    color: $color-primary-white;
  }
}
.ExpanderCopyV2 {
  color:$color-grey-medium;
  .isDark & {
    color: $color-primary-white;
  }
}