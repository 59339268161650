
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
  &.dark {
    background-color: $color-primary-grey;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    padding: 24px 0;
    row-gap: 24px;
  }
}
.TopContent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  h2 {
    color: $color-primary-teal !important;
  }
}
.dark {
  .TopContent {
    p {
      color: white;
    }
  }
}
:global(.groupedWidgetsLayout) {
  .Container {
    padding: 0;
    box-shadow: none;
  }
}