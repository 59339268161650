
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Slide {
  width: calc(80% - 12px);
  margin-right: spacing(2);

  @include mq($breakpoint-tablet) {
    width: calc(50% - 12px);
    margin-right: spacing(3);
  }

  @include mq($breakpoint-desktop) {
    margin-right: spacing(3);
    min-width: 120px;
    width: calc(33.3% - 16px);
  }
}

.Information {
  margin: spacing(1) 0;
  color: $color-primary-grey;
}

.Wrapper {
  border: 1px solid $color-primary-grey-light;
}

.isDark {
  .Information {
    color: $color-primary-white;
  }
}

:global(.designV2) {
  & .Information {
    h3 {
      color: $color-grey-medium;
      font-size: 18px;
      line-height: 1.2;
    }
   
  }
}