
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ItemsList {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
}
