
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.HeroWrap {
  position: relative;
}

.CarouselContainer {
  width: 100%;
  height: 100%;
}
.CarouselContent {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.CarouselContentWrap {
  display: flex;
  height: auto;
}
.CarouselDots {
  display: flex;
  justify-content: center;
  column-gap: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 24px;
  z-index: 4;
}
.CarouselDot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all ease-in-out 0.3s;
  &.ActiveCarouselDot {
    background-color: white;
  }
  &:hover {
    background-color: $color-primary-teal;
    opacity: 0.5;
  }
}
