
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  position: relative;
  width: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, transparent 40%, rgba(255,255,255,0.3) 100%);
    z-index: 0;
  }
  @include mq($breakpoint-desktop) {
    min-height: 580px;
  }
}

.ContentWrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 2;
}

.Content {
  padding: 16px;
  display: flex;
  align-items: center;
  column-gap: 26px;
  margin: 0 auto;
  @include mq($breakpoint-desktop) {
    padding: 16px 32px;
    max-width: 960px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 16px 0;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 960px;
  }
}

.Headline {
  font-family: $font-heading;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.96px;
  color: white;
  line-height: 1.06;
  @include mq($breakpoint-desktop) {
    font-size: 36px;
  }
}

.RatingIconWrap {
  max-width: 62px;
  min-width: 51px;
}
.RatingIcon {
  width: 100% !important;
  height: 100% !important;
}

.NavContentWrap {
  color: white;
  padding: 24px 16px;
  font-size: 20px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  @include mq($breakpoint-desktop) {
    padding: 24px 32px;
    max-width: 1248px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 24px 0;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
}

.DesktopLink {
  display: none;
  @include mq($breakpoint-desktop) {
    display: inline;
  }
}
.MobileLink {
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.NavLink {
  &:hover {
    color: $color-primary-teal;
    text-decoration: underline;
  }
}
