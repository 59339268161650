
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.LogoCarousel {
  position: relative;
  margin: 0 spacing(3) spacing(2);
  @include mq($breakpoint-desktop) {
    margin: 0 spacing(4) spacing(3);
  }
}

:global(.column) {
  & .LogoCarousel {
    margin: 0;
  }
}

:global(.groupedWidgetsLayout) {
  & .LogoCarousel {
    margin: 0;
  }
}