
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
    font-weight: 800;
    position: relative;
    text-align: center;
  
    
}
.Content {
    padding: 10px 45px;
    .QuoteProse {
        font-size: 18px;
        line-height: 1.3;
    }
    @include mq($breakpoint-desktop) {
       padding: 10px 65px;
        .QuoteProse {
font-size: 20px;
    }
    }
    &.DarkContent {
        color: white;
    }
}
.DoubleQuote {
    position: absolute;
    transform: scale(0.9);
    transform-origin: center;
    @include mq($breakpoint-desktop) {
       transform: scale(1.5);
    }
}
.OpenQuote {
    left: 0;
    top: 0;
}
.CloseQuote {
    transform: scale(0.9) rotate(180deg);
    right: 0;
    bottom: 0;
    @include mq($breakpoint-desktop) {
       transform: scale(1.5)  rotate(180deg);
    }
}