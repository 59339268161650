
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  &.dark {
    background-color: $color-primary-grey;
  }
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
  }

  @include mq($breakpoint-desktop) {
    position: relative;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    padding: 24px 0;
    row-gap: 24px;
  }
}
.TopContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include mq($breakpoint-desktop) {
    row-gap: 44px;
  }
  
}
.TitleWrap {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  line-height: 1.34;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  h1 {
    color: $color-primary-teal !important;
  }
}
.SearchWrap {
  display: flex;
  border-radius: 9px;
  border: 1px solid $color-grey-medium;
  position: relative;
  padding: 0 12px;
  width: 100%;
  &:focus-within {
    border: 1px solid $color-primary-teal;
  }
  @include mq($breakpoint-desktop) {
    max-width: 350px;
  }
}
.SearchInput {
  display: block;
  padding: 12px 0;
  width: 100%;
  border: none;
  &:focus,
  &:visited,
  &:focus-visible {
    border: none;
    outline: none;
  }
  .dark & {
    background-color: $color-primary-grey ;
    color: white;
    &::placeholder {
      color: white;
    }
  }
}
.Description {
  white-space: pre-wrap;
  color: $color-grey-dark;
  font-size: 18px;
  line-height: 1.3;
 
  
  p, h1, h3 {
    color:  $color-grey-dark;
  }
p {
  margin-top: 10px !important;
}
h2 {
  font-size: 28px !important;
  font-weight: 700;


}
ul {
      margin-top: 10px !important;
    }
    li {
      font-size: 18px !important;
      color: $color-grey-medium;
      margin-bottom: 10px !important;
    }

  .dark & {
    color: white;
    p,h1,h3,li {
      color: white;
    }
  }
  
}
.List {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include mq($breakpoint-tabletLandscape) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 44px;
    column-gap: 24px;
  }
  @include mq($breakpoint-desktopMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.LoadMoreButton {
  background-color: $color-primary-teal;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.64px;
  padding: 16px 29px;
  border-radius: 9px;
  width: 100%;
  @include mq($breakpoint-desktop) {
    max-width: 180px;
    margin: 0 auto;
  }
}

.FilterWrap {
  padding: 5px 0;
  color: $color-primary-grey;
  font-size: 16px;
  font-family: $font-body;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 6px;
  line-height: 1.4;
  text-transform: capitalize;
  @include mq($breakpoint-desktop) {
    font-size: 22px;
  }
  .dark & {
    color: white;
  }
}
.CheckboxWrap {
  position: relative;
  width: 20px;
  height: 20px;
}
.CheckboxInput {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  appearance: none;
  display: grid;
  place-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  z-index: 2;
  opacity: 0;
}
.CheckedInput {
  border-radius: 5px;
  overflow: hidden;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid $color-grey-medium;
  background-color: $color-grey-light;
  opacity: 0.5;
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.CheckboxInput:checked ~ .CheckedInput {
  background-color: $color-primary-teal;
  border-color: $color-primary-teal;
  opacity: 1;
  &::after {
    display: block;
  }
}
.FilterContainer {
  position: relative;
}
.ToggleButton {
  border-radius: 6px;
  padding: 15px 10px;
  background-color: white;
  border: 1px solid $color-grey-light;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
  color: $color-primary-grey;
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: $font-body;
  align-items: center;
  justify-content: space-between;
  position: relative;
  img {
    transition: 0.3s ease-in-out;
    transform: rotate(180deg);
  }
  &.ActiveToggleButton {
    border-bottom: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 10;

    img {
      transform: rotate(360deg);
    }
  }
  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.FiltersBar {
  padding: 0 10px 15px;
  display: flex;
  row-gap: 10px;
  column-gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  transition: 0.15s ease-in-out;

  @include mq($breakpoint-desktop) {
    flex-direction: row;
    opacity: 1;
    z-index: 1;
    position: relative;
    column-gap: 24px;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}

:global(.groupedWidgetsLayout) {
  & .Container {
   box-shadow: none;
   padding: 0;
  }
}
