
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Title {
  margin-bottom: spacing(1);

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(2.5);
  }
}
.TitleV2 {
  font-size: 28px;
  font-weight: 800;
  color: $color-primary-teal;
  padding-bottom: spacing(2);
  font-family: $font-heading;
  @include mq($breakpoint-desktop) {
     font-size: 32px;
  }
}
