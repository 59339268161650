
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContainerWrapper {
  position: relative;
}
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.025);
  }
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    padding: 44px;
    max-width: 1560px;
  }
}
.Content {
  display: flex;
  flex-direction: column;
}
.TopContent {
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  @include mq($breakpoint-desktop) {
    padding-bottom: 32px;
  }
}
.ContentHeading {
  font-family: $font-heading;
  font-size: 28px;
  color: $color-primary-teal;
  font-weight: 900;
  line-height: 1.21;
  @include mq($breakpoint-desktop) {
    font-size: 34px;
  }
}
