
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
    column-gap: 44px;
     &.SingleItemContainer {
      column-gap: 30px;
    }
  }
}
.Content {
  border-bottom: none;
  width: 100%;
  @include mq($breakpoint-desktop) {
    border-bottom: 1px solid $color-grey-light;
    &.SingleItemContent {
      border-bottom: none;
    }
  }
}
.RatingIconWrap {
  max-width: 50px;
  min-width: 50px;
  @include mq($breakpoint-desktop) {
    max-width: 80px;
    min-width: 55px;
  }
}
.RatingIcon {
  width: 100% !important;
  height: 100% !important;
}
.MediaTitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.05;
  font-family: $font-heading;
  color: black;
  @include mq($breakpoint-desktop) {
    font-size: 36px;
  }
  .dark & {
    color: $color-primary-teal;
  }
}

.TopContent {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.ShortFormInsight {
  padding-top: 16px;
  text-transform: lowercase;
  font-weight: 600;
  @each $name, $value in $color-rating-list {
    &.Rating-#{$name} {
      color: $value;
    }
  }
   @include mq($breakpoint-desktop) {
    font-size: 24px;
  }
}
.SummaryWrap {
  font-size: 18px;
  font-weight: 500px;
  line-height: 1.3;
  letter-spacing: -0.09px;
  text-overflow: ellipsis;
  font-family: $font-body;
  color: $color-grey-medium;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  padding: 16px 0;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin: 16px 0;
  .SingleItemContent & {
    border-bottom: none;
  }

  @include mq($breakpoint-desktop) {
    display: block;
    margin-bottom: 24px;
    border-top: none;
    padding-bottom: 24px;
    &.NoPadding {
      padding: 0;
    }
    .SingleItemContent & {
      margin-bottom: 0;
      padding-bottom: 24px;
    }
  }
  .dark & {
    color: white;
  }
}
.MobilePoster {
  display: block;
  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.DesktopPoster,
.DesktopSummary {
  display: none;
  @include mq($breakpoint-desktop) {
    display: block;
  }
}
.DesktopPoster {
  width: 100%;
  max-width: 300px;
  @include mq($breakpoint-desktopLarge) {
    max-width: 400px;
    .SingleItemContainer & {
      max-width: 300px;
    }
  }
}
.MobileSummary {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.ReadMore {
  color: $color-grey-medium;
  font-weight: 700;
  text-decoration: underline;
}
.VisualAdviceOverviewTitle {
  font-family: $font-heading;
  display: none;
  font-size: 24px;
  color: $color-primary-teal;
  margin-bottom: 24px;
  @include mq($breakpoint-desktop) {
    display: block;
  }
}