
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.LoadMoreButton {
  background-color: $color-primary-teal;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.64px;
  padding: 16px 29px;
  border-radius: 9px;
  width: 100%;
  @include mq($breakpoint-desktop) {
    max-width: 180px;
    margin: 0 auto;
  }
}
