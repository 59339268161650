
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 
    );
  }

  @include mq($breakpoint-desktopLarge) {
    height: 0;
    padding-top: 30.78%;
    min-height: auto;
    &.MobileContainer {
      display: none;
      
    }
    &.DesktopContainer {
      display: flex;
    }
  }
  
}
.BackgroundImage {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  &.DesktopBackgroundImage {
    display: none;
  }
  @include mq($breakpoint-desktop) {
    &.MobileBackgroundImage {
    display: none;
  }
  &.DesktopBackgroundImage {
    display: block;
  }
  }
}
.ContentWrap {
  position: relative;
  z-index: 6;
  margin: 0 auto;
  width: 100%;
  padding: 40px 0;
  @include mq($breakpoint-desktop) {
    max-width: 1248px;
    padding: 50px 0;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
    margin-top: -30.78%; 
    padding: 60px 0;
  }
}
.Content {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include mq($breakpoint-desktop) {
    padding: 0 32px;
    width: 65%;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 0;
  }
  @include mq($breakpoint-desktopLarge) {
    width: 45%;
  }
}
.Title {
  font-family: $font-heading;
  color: white;
  font-size: 20px;
  line-height: 1.2;
  h1 {
    font-size: 28px;
    font-weight: 900;
  }
  h2 {
    font-size: 25px;
    font-weight: 600;
  }
  p {
    padding-top: 12px;
    font-family: $font-body;
  }
  @include mq($breakpoint-desktop) {
    font-size: 25px;
    h1 {
    font-size: 36px;
  }
  h2 {
    font-size:28px;
  }
  }
  @include mq($breakpoint-desktopLarge) {
    font-size: 28px;
   
  h2 {
    font-size: 30px;
  }
  }
}
.SubHeading {
  color: white;
  font-size: 24px;
  font-family: $font-body;
  font-weight: 400;
}
