
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  display: flex;
  min-width: 0;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  border-radius: 9px;
  flex-direction: column;
  position: relative;
  min-height: 372px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  &:hover {
    filter: brightness(110%);
  }
  @include mq($breakpoint-tabletLandscape) {
    min-height: 350px;
  }
}
.Label {
  padding: 12px 16px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  font-weight: 700;
  color: white;
  line-height: 1.2;
  min-height: 72px;
  display: flex;
  align-items: center;
}
