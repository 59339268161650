
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
color: white;
font-size: 20px;
  font-weight: 400;
}

.BreadCrumbWrapper {
  background-color: $color-primary-teal;
  padding: spacing(1) spacing(4);
  height: 176px;
  display: none;
  margin: 16px 0 -160px 0;

  @include mq($breakpoint-desktop) {
    display: block;
  }
  @include mq($breakpoint-desktopMedium) {
    display: block;
  }
}

.BreadCrumb {
  line-height: 2;
  color: $color-primary-white;
  a {
    font-size: 20px;
  }
  &:hover {
    transition: $easing-standard $duration-short;
    &::after {
      color: $color-primary-white;
    }
    a {
      text-decoration: underline;
    }
  }

  &:not(:last-of-type)::after {
    content: ' / ';
  }
}
.NavLink {
  &:hover {
    text-decoration: underline;
  }
}

:global(.groupedWidgetsLayout) {
  .Container {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    &.dark {
      background-color: transparent !important;
    }
  }
}