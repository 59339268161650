
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContainerWrap {
  width: 100%;
  min-width: 100%;
}
.Container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 100%;
 
  @include mq($breakpoint-desktop) {
    padding-top: 30.78%;
    background-image: none !important;
  
    
  }
 

}
.DesktopImage {
  display: none;

  @include mq($breakpoint-desktop) {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
  }
}
.HalfWidthImage {
  width: 60%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ContentWrap {
  position: relative;
  z-index: 6;
  margin: 0 auto;
  width: 100%;
  @include mq($breakpoint-desktop) {
    max-width: 1248px;
    margin-top: -30.78%;
   
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px; 
    
  }
}
.ContentDesktop {
  padding: 0 24px;
  flex-direction: column;
  row-gap: 12px;
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
    padding: 0 32px;
    width: 50%;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 0;
  }
}
.Title {
  font-family: $font-heading;
  color: white;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.1;
  @include mq($breakpoint-desktop) {
    font-size: 32px;
    padding-right: 40px;
  }
  @include mq($breakpoint-desktopLarge) {font-size: 36px;}
}
.SubHeading {
  color: white;
  font-size: 24px;
  font-family: $font-body;
  font-weight: 400;
}

.ReadMore {
  font-size: 20px;
  font-family: $font-body;
  font-weight: 600;
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.RatingBkgWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  @include mq($breakpoint-desktop) {
    display: block;
  }
  @each $name, $value in $color-rating-list {
    &.RatingBkg-#{$name} {
      background-color: $value;
    }
  }
  &.SharpEdge {
clip-path: polygon(0 0, 48% 0, 60% 100%, 0 100%);
  }
  &.CurveEdge {
     clip-path: circle(50% at 15% 50%);
     @include mq($breakpoint-desktopLarge) {
      clip-path: circle(40% at 25% 50%);
     }
  }
}
.ContentMobile {
  padding: 24px 16px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-height: 180px;
  height: 100%;
  @each $name, $value in $color-rating-list {
    &.RatingBkg-#{$name} {
      background-color: $value;
    }
  }
  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.Sell {
  color: white;
  font-size: 24px;
  display: none;
  line-height: 1.1;
  
  @include mq($breakpoint-desktop) {
    display: block;
    padding-top: 12px;
  }
}