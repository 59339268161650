
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MainContent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
  z-index: 2;
  @include mq($breakpoint-desktop) {
    display: grid;
    grid-template-columns: 40% auto;
    width: 100%;
    align-items: flex-start;
    column-gap: 20px;
  }
}
.RightMainContent {
  row-gap: 16px; 
  display: flex;
   flex-direction: column;
  
  @include mq($breakpoint-desktop) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    column-gap: 20px;

  }
}
.RatingWrap {
  display: flex;
  align-items: center;
  row-gap: 15px;
  column-gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq($breakpoint-tablet) {
    column-gap: 15px;
  }
  @include mq($breakpoint-desktop) {
    flex-wrap: nowrap;
    column-gap: 20px;
    padding-right: 20px;
    border-right: 1px solid $color-grey-light;
   
  }
  @include mq($breakpoint-desktopLarge) {
    column-gap: 24px;
  }
}
.RatingItemWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  position: relative;
  @include mq($breakpoint-desktop) {
    row-gap: 20px;
  }
  &:hover .CheckboxWrap {
    background-color: $color-primary-teal;
    
  }
}
.RatingItemIconWrap {
  max-width: 37.5px;
  @include mq($breakpoint-mobileLandscape) {
    max-width: 50px;
  }
  @include mq($breakpoint-desktop) {
    max-width: 65px;
    max-height: 65px;
   
  }
   &.RatingItemIconWrapCustomised {
      max-width: auto;
      transform: scale(1.125);
      transform-origin: center;
      max-height: auto;
    }
}
.RatingItemIcon {
  width: 100% !important;
  height: 100% !important;
}
.CheckboxWrap {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: background .3s ease-in-out;

}
.CheckboxInput {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  appearance: none;
  display: grid;
  place-content: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  z-index: 2;
  opacity: 0;
}
.RatingItemCheckBox {
  border-radius: 5px;
  overflow: hidden;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid $color-grey-medium;
  background-color: $color-grey-light;
  opacity: 0.5;
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.CheckboxInput:checked ~ .RatingItemCheckBox {
  background-color: $color-primary-teal;
  border-color: $color-primary-teal;
  opacity: 1;
  &::after {
    display: block;
  }
}

.Desktop {
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
  }
}
.ShowRating {
  padding: 20px 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
}

.SelectWrap {
  width: 100%;
  position: relative;
  // @include mq($breakpoint-desktop) {
  //   max-width: 200px;
  //   min-width: 200px;
  // }
  // @include mq($breakpoint-desktop) {

  //   width: 33.33%;
  // }
}
.ToggleButton {
  border-radius: 6px;
  padding: 15px 10px;
  background-color: white;
  border: 1px solid $color-grey-light;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
  color: $color-primary-grey;
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: $font-body;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  span {
     -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  }
  img {
    transition: 0.3s ease-in-out;
    transform: rotate(180deg);
  }
  &.ActiveToggleButton {
    border-bottom: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .SelectWrap & {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    }

    img {
      transform: rotate(360deg);
    }
  }
  &:hover {
    border-color: $color-primary-teal;
  }
  @include mq($breakpoint-desktop) {

    span {
      max-width: 234px;
    }
  }
}
.ToggleContent {
  background-color: white;
  position: absolute;
  padding: 0 10px 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  border: 1px solid $color-grey-light;
  border-top: none;
  border-radius: 6px;
  opacity: 0;
  transition: 0.15s ease-in-out;
  z-index: -100;
  &.ActiveToggleContent {
    opacity: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 10;
    .ContentOptionWrap {
      display: block;
    }
  }
}
.ContentOptionWrap {
  display: none;
}
.ContentOption {
  padding: 5px 0;
  color: $color-primary-grey;
  font-size: 16px;
  font-family: $font-body;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition-delay: 0.1s;
  width: 100%;
  text-transform: capitalize;
  .ActiveToggleContent & {
    opacity: 1;
  }
  &:hover {
    color: $color-primary-teal;
  }
}
.Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
  &.ActiveOverlay {
    z-index: 1;
  }
}
.AdultContentWrap {
  display: flex;
  align-items: center;
  padding-top: 16px;
  color: $color-primary-grey;
  column-gap: 16px;
}