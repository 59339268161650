
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MediaGrid {
  padding: spacing(2) spacing(3);
  padding-right: 0;

  @include mq($breakpoint-tabletLandscape) {
    padding: spacing(3) spacing(4);
  }
}

.MediaWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(2);
  padding-right: spacing(3);
  font-weight: 700;

  @include mq($breakpoint-desktop) {
    padding-right: 0;
    margin-bottom: spacing(3);
  }
}

.ImageWrapper {
  margin-bottom: spacing(1.5);
  position: relative;

  @include mq($breakpoint-tablet) {
    margin-bottom: spacing(2.5);
  }

  @include mq($breakpoint-desktopLarge) {
    margin-bottom: spacing(3);
  }

  img {
    position: absolute;
    bottom: 0;
  }
}

:global(.groupedWidgetsLayout) {
  .MediaGrid {
    padding: 0;
  }
}
.designV2 {
  .TitleWrapper {
    h2 {
      font-size: 28px;
      font-family: $font-heading;
      font-weight: 800;
      @include mq($breakpoint-desktop) {
        font-size: 32px;
      }
    }
  }
}