
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContactDetails {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
  &.padding {
    padding: 16px;
  
  @include mq($breakpoint-tablet) {
    padding: 32px;

  }
  @include mq($breakpoint-desktopLarge) {
    padding: 44px;

  }
  }
}

.Title {
  padding-bottom: spacing(2);

  @include mq($breakpoint-desktopMedium) {
    padding-bottom: spacing(4);
  }
  .designV2 & {
    font-size: 28px;
    @include mq($breakpoint-desktop) {
      font-size: 32px;
    }
    }
}

.Wrapper {
  padding: spacing(2) 0;
  border-top: 1px solid $color-primary-grey-light;
  border-bottom: 1px solid $color-primary-grey-light;

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) 0;
  }

}

.DetailWrapper {
  padding-top: spacing(1);
  
  
}

.ContactDetail {
  display: inline;
  transition: $duration-short $easing-standard;

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-teal;
  }

  &:not(:last-child)::after {
    content: ' | ';
    padding: 0 spacing(1);
    color: $color-primary-black;
  }
  .designV2 & {
    color: $color-grey-medium;
  }
}

.isDark {
  .ContactDetail,
  .ContactDetail::after,
  .Wrapper {
    color: $color-primary-white;
    border-color: $color-primary-grey-mid;
  }

  .ContactDetail:hover {
    color: $color-primary-teal;
  }
  .ContactName,
  .DetailWrapper {
    color: white !important;
  }
}
.ContactName {
  font-size: 18px;
  color: $color-grey-medium;
  @include mq($breakpoint-desktopMedium) {
    font-size: 24px;
  }
}

:global(.groupedWidgetsLayout) {
  & .ContactDetails {
   box-shadow: none;
   padding: 0;
  }
}
