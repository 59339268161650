
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);

  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
  }

  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
  &.dark {
    background-color: $color-primary-grey;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    padding: 24px 0;
    row-gap: 24px;
  }
}
.TopContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include mq($breakpoint-desktop) {
    row-gap: 44px;
  }
}
.TitleWrap {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  line-height: 1.34;
  @include mq($breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  h1 {
    color: $color-primary-teal !important;
  }
}
.SearchWrap {
  display: flex;
  border-radius: 9px;
  border: 1px solid $color-grey-medium;
  position: relative;
  padding: 0 12px;
  width: 100%;
  &:focus-within {
    border: 1px solid $color-primary-teal;
  }
  @include mq($breakpoint-desktop) {
    max-width: 350px;
  }
}
.SearchInput {
  display: block;
  padding: 12px 0;
  width: 100%;
  border: none;
  &:focus,
  &:visited,
  &:focus-visible {
    border: none;
    outline: none;
  }
.dark & {
    background-color: $color-primary-grey ;
    color: white;
    &::placeholder {
      color: white;
    }
  }

}
.Description {
  white-space: pre-wrap;
  color: $color-grey-dark;
  font-size: 18px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  div {
    margin-top: 0;
  }
  @include mq($breakpoint-desktop) {
    row-gap: 32px;
  }
  p {
    margin-top: 0 !important;
  }
}
.MainContent {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    margin-top: 44px;
    row-gap: 24px;
  }
  .dark & {
    color: white;
  }
}
.LoadMoreButton {
  background-color: $color-primary-teal;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.64px;
  padding: 16px 29px;
  border-radius: 9px;
  width: 100%;
  @include mq($breakpoint-desktop) {
    max-width: 180px;
    margin: 0 auto;
  }
}
:global(.groupedWidgetsLayout) {
  .Container {
    box-shadow: none;
    padding:0;
  }
}