
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  background-color: white;
  border-radius: 9px;
  padding: 16px;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  @include mq($breakpoint-tablet) {
    padding: 24px 32px;
    max-width: 1248px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
  &.dark {
    background-color: $color-primary-grey;
  }
}
.Content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    padding: 24px 0;
    row-gap: 24px;
  }
}
.Title {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.35;
  font-family: $font-heading;
  color: $color-primary-teal;
  @include mq($breakpoint-desktop) {
    font-size: 36px;
  }
}
.LinkList {
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}
.LinkItem {
  font-size: 16px;
  font-weight: 900;
  line-height: 2.4;
  font-family: $font-heading;
  background-color: $color-grey-light;
  padding: 4px 12px;
  border-radius: 9px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $color-primary-teal;
    color: white;
  }
  @include mq($breakpoint-desktop) {
    font-size: 18px;
  }
}

:global(.groupedWidgetsLayout) {
  .Container {
    box-shadow: none;
    padding: 0;
  }
}