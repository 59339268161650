
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.Heading {
  color: $color-common-black;
  margin-top: spacing(2.25);

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(3);
  }
  .designV2 & {
    font-size: 24px;
    line-height: 1.4;
  }
}

.Body {
  color: $color-primary-grey;
  margin-top: spacing(1.5);

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(2);
  }
   .designV2 & {
    font-size: 18px;
    line-height: 1.4;
    color: $color-grey-medium;
  }
}

.isDark {
  .Heading,
  .Body {
    color: $color-primary-white;
  }
}
